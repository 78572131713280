import { lazy } from 'react';

const routes = [
  {
    path: '/errors/error-401',
    component: lazy(() => import('./pages/desktop/errors/Error401')),
    mobile: lazy(() => import('./pages/mobile/errors/Error401'))
  },
  {
    path: '/reports/cash-transaction',
    component: lazy(() =>
      import('./pages/desktop/cashTransactionReport/CashTransactionReport')
    )
  },
  {
    path: '/reports/bank-transaction',
    component: lazy(() =>
      import('./pages/desktop/bankTransactionReport/BankTransactionReport')
    )
  },
  {
    path: '/reports/trade-currency',
    component: lazy(() =>
      import('./pages/desktop/tradeCurrencyReport/TradeCurrencyReport')
    )
  },
  {
    path: '/reports/transfer-between-accounts',
    component: lazy(() =>
      import(
        './pages/desktop/transferBetweenAccountsReport/TransferBetweenAccountsReport'
      )
    )
  },
  {
    path: '/reports/interbank-transaction',
    component: lazy(() =>
      import(
        './pages/desktop/InterBankTransactionReport/InterbankTransactionReport'
      )
    )
  },
  {
    path: '/reports/cheque',
    component: lazy(() => import('./pages/desktop/chequeReport/ChequeReport'))
  },
  {
    path: '/reports/outgoing-incoming-draft',
    component: lazy(() =>
      import(
        './pages/desktop/outgoingOrincomingDraftReport/OutgoingOrIncomingDraftReport'
      )
    )
  },
  {
    path: '/reports/draft-conduction',
    component: lazy(() =>
      import('./pages/desktop/draftConductionReport/DraftConductionReport')
    )
  },
  {
    path: '/reports/customers-list',
    component: lazy(() =>
      import('./pages/desktop/customerListReport/CustomersListReport')
    )
  },
  {
    path: '/reports/debtor-and-creditor',
    component: lazy(() =>
      import('./pages/desktop/debtorAndCreditorReport/DebtorAndCreditorReport')
    )
  },
  {
    path: '/reports/account-information',
    component: lazy(() =>
      import(
        './pages/desktop/customerAccountReport/CommonAccountInformationReport'
      )
    )
  },
  {
    path: '/reports/cheques-list',
    component: lazy(() =>
      import('./pages/desktop/chequesListReport/ChequesListReport')
    )
  },
  {
    path: '/reports/branch-account',
    component: lazy(() =>
      import('./pages/desktop/branchAccountReport/BranchAccountReport')
    )
  },
  {
    path: '/reports/bank-book',
    component: lazy(() =>
      import('./pages/desktop/bankBookReport/BankBookReport')
    )
  },
  {
    path: '/reports/draft-documents-list',
    component: lazy(() => import('./pages/desktop/DraftDocumentsListReport'))
  },
  {
    path: '/reports/draft-orders-list',
    component: lazy(() =>
      import('./pages/desktop/draft-orders-list/DraftOrdersList')
    )
  },
  {
    path: '/reports/draft-miscelaneous',
    component: lazy(() =>
      import('./pages/desktop/miscelaneousDraftReport/MisccelaneousDraft')
    )
  },
  {
    path: '/reports/account-balance',
    component: lazy(() =>
      import('./pages/desktop/accountBalanceReport/AccountBalanceReceipt')
    )
  },
  {
    path: '/reports/branch-account-balance',
    component: lazy(() =>
      import(
        './pages/desktop/branchAccountBalanceReport/BranchAccountBalanceReceipt'
      )
    )
  },
  {
    path: '/*',
    component: lazy(() => import('./pages/desktop/errors/Error404')),
    mobile: lazy(() => import('./pages/mobile/errors/Error404'))
  }
];

export default routes;

// *********************** queries ******************************

export const GET_CUSTOMERS = {
  routesNum: 2,
  gqlSchema: (items) => `
  query GetCustomers($customerItem: CustomerQueryRequest) {
    identityInformationQueries {
      getCustomers(customerItem: $customerItem) {
         ${items}
      }
    }
  }
  `,
  customerType: {
    ALL: 'ALL',
    REAL_CUSTOMER: 'REAL_CUSTOMER',
    LEGAL_CUSTOMER: 'LEGAL_CUSTOMER'
  }
};

export const GET_CUSTOMERS_WITH_ACCOUNTS = {
  routesNum: 2,
  gqlSchema: (items) => `
  query GetCustomersWithAccounts($customerItem: CustomerQueryRequest) {
    accountingQueries {
      getCustomersWithAccounts(customerItem: $customerItem) {
         ${items}
      }
    }
  }
  `
};

export const GET_USER_CURRENCY = {
  routesNum: 2,
  gqlSchema: (items) => `
  query GetUserCurrency($currencyCode: String) {
    systemManagementQueries {
      getUserCurrency(currencyCode: $currencyCode) {
        ${items}
      }
    }
  }
`
};

export const GET_USER_CURRENCIES = {
  routesNum: 2,
  gqlSchema: (items) => `
  query GetUserCurrencies {
    systemManagementQueries {
      getUserCurrencies {
        ${items}
      }
    }
  }
`
};

export const GET_DRAFT_CURRENCIES = {
  routesNum: 2,
  gqlSchema: (items) => `
  query GetDraftCurrencies($branchId: String!) {
    branchManagementQueries {
      getDraftCurrencies(branchId: $branchId) {
        ${items}
      }
    }
  }
`
};

export const GET_TRANSFER_BETWEEN_ACCOUNTS = {
  routesNum: 2,
  gqlSchema: (items) => `
    query GetTransferBetweenAccounts($transactionId: String) {
      accountingQueries {
        getTransferBetweenAccounts(transactionId: $transactionId) {
          ${items}
        }
      }
    }
  `
};

export const GET_BANK_TRANSACTION = {
  routesNum: 2,
  gqlSchema: (items) => `
    query GetBankTransaction($transactionId: String) {
      bankingQueries {
        getBankTransaction(transactionId: $transactionId) {
          ${items}
        }
      }
    }
  `
};

export const GET_CASH_TRANSACTION = {
  routesNum: 2,
  gqlSchema: (items) => `
    query GetCashTransaction($transactionId: String) {
      accountingQueries {
        getCashTransaction(transactionId: $transactionId) {
          ${items}
        }
      }
    }
  `
};

export const GET_WHATSAPP_DRAFTS = {
  routesNum: 2,
  gqlSchema: (items) => `
  query getWhatsappDrafts($draftIds:[String!]){
    branchManagementQueries{
      getWhatsappDraftReports(draftIds:$draftIds){
          ${items}
        }
      }
    }
  `
};

export const GET_DRAFT = {
  routesNum: 2,
  gqlSchema: (items) => `
    query GetDraft($transactionId: String) {
      branchManagementQueries {
        getDraft(transactionId: $transactionId) {
          ${items}
        }
      }
    }
  `
};

export const GET_TRADE_CURRENCY = {
  routesNum: 2,
  gqlSchema: (items) => `
    query GetTradeCurrency($transactionId: String) {
      accountingQueries {
        getTradeCurrency(transactionId: $transactionId) {
          ${items}
        }
      }
    }
  `
};

export const GET_DRAFTS = {
  routesNum: 2,
  gqlSchema: (items) => `
    query GetDrafts($draftItem: DraftQueryRequest) {
      branchManagementQueries {
        getDrafts(draftItem: $draftItem) {
          ${items}
        }
      }
    }
  `,
  executeStatus: {
    UNEXECUTED: 'UNEXECUTED',
    ALL: 'NOEFFECT'
  }
};

export const GET_PENDING_DRAFTS = {
  routesNum: 2,
  gqlSchema: (items) => `
    query GetPendDrafts($getPendDraftsQueryRequest: GetPendDraftQueryRequest) {
      branchManagementQueries {
        getPendDrafts(getPendDraftsQueryRequest: $getPendDraftsQueryRequest) {
          ${items}
        }
      }
    }
  `,
  executeStatus: {
    UNEXECUTED: 'UNEXECUTED',
    ALL: 'NOEFFECT'
  }
};

export const GET_DRAFTS_STATUS = {
  routesNum: 2,
  gqlSchema: (items) => `
    query($draftItem:DraftQueryRequest!){
      branchManagementQueries{
        getDraftsStatus(draftItem:$draftItem){
        ${items}
      }
    }
  } 
  `
};

export const GET_ACCOUNT_STATUS = {
  routesNum: 2,
  gqlSchema: (items) => `
    query GetAccountStatus($accountNumber: String) {
      accountingQueries {
        getAccountInformation(accountNumber: $accountNumber) {
          ${items}
        }
      }
    }
  `
};

export const GET_COMMON_ACCOUNT_LAST_CHECKOUT = {
  routesNum: 2,
  gqlSchema: (items) => `
    query GetLastCheckout($accountNumber: String) {
      accountingQueries {
        getLastCheckout(accountNumber: $accountNumber) {
          ${items}
        }
      }
    }
  `
};

export const GET_COMMON_ACCOUNT_TRANSACTIONS = {
  routesNum: 2,
  gqlSchema: (items) => `
    query GetAccountTransactions($transactionItem: TransactionQueryRequest) {
      accountingQueries {
        getAccountTransactions(transactionItem: $transactionItem) {
          ${items}
        }
      }
    }
  `,
  documentType: {
    TRANSFER_BETWEEN_ACCOUNTS: 1,
    OUTGOING_DRAFT: 2,
    INCOMING_DRAFT: 3,
    BANK_TRANSACTION: 4,
    CASH_TRANSACTION: 5,
    TRADE_CURRENCY: 6,
    CHEQUE_TRANSACTION: 7,
    CHARGE_TRANSACTION: 8,
    UNKNOWN_TRANSACTION: 9,
    CREDIT: 10,
    READ_ONLY: 11
  }
};

export const GET_BRANCHES = {
  routesNum: 2,
  gqlSchema: (items) => `
    query GetBranches($branchItem: BranchQueryRequest) {
      branchManagementQueries {
        getBranches(branchItem: $branchItem) {
          ${items}
        }
      }
    }
  `,
  connectionType: {
    ALL: 'ALL',
    MY_CONNECTED: 'MY_CONNECTED',
    MY_ISOLATED: 'MY_ISOLATED',
    OTHER: 'OTHER'
  }
};

export const GET_BRANCH_ACCOUNT_STATUS = {
  routesNum: 2,
  gqlSchema: (items) => `
    query GetBranchAccountStatus($branchInfoItem: BranchAccountStatusQueryRequest) {
      branchManagementQueries {
        getBranchAccountStatus(branchInfoItem: $branchInfoItem) {
          ${items}
        }
      }
    }
  `
};

export const GET_MIRROR_BRANCH_ACCOUNT_STATUS = {
  routesNum: 2,
  gqlSchema: (items) => `
    query GetMirrorBranchAccountStatus($branchId: String) {
      branchManagementQueries {
        getMirrorBranchAccountStatus(branchId: $branchId) {
          ${items}
        }
      }
    }
  `
};

export const GET_BRANCH_ACCOUNT_TRANSACTIONS = {
  routesNum: 2,
  gqlSchema: (items) => `
    query GetBranchAccountTransactions($transactionItem: DraftOrderDocumentQueryRequest) {
      branchManagementQueries {
        getBranchAccountTransactions(transactionItem: $transactionItem) {
          ${items}
        }
      }
    }
  `,
  draftType: {
    ALL: 'ALL',
    INCOMING: 'INCOMING',
    OUTGOING: 'OUTGOING'
  }
};

export const GET_MIRROR_BRANCH_ACCOUNT_TRANSACTIONS = {
  routesNum: 2,
  gqlSchema: (items) => `
    query GetMirrorBranchAccountTransactions($transactionItem: MirrorBranchDraftOrderDocumentQueryRequest) {
      branchManagementQueries {
        getMirrorBranchAccountTransactions(transactionItem: $transactionItem) {
          ${items}
        }
      }
    }
  `
};

export const GET_BRANCH_ACCOUNT_LAST_CHECKOUT = {
  routesNum: 2,
  gqlSchema: (items) => `
    query GetBranchAccountLastCheckout($branchId: String) {
      branchManagementQueries {
        getBranchLastCheckout(branchId: $branchId) {
          ${items}
        }
      }
    }
  `
};

export const GET_MIRROR_BRANCH_LAST_CHECKOUT = {
  routesNum: 2,
  gqlSchema: (items) => `
    query GetMirrorBranchLastCheckout($branchId: String) {
      branchManagementQueries {
        getMirrorBranchLastCheckout(branchId: $branchId) {
          ${items}
        }
      }
    }
  `
};

export const GET_BANK_LAST_CHECKOUT = {
  routesNum: 2,
  gqlSchema: (items) => `
    query GetBankCheckouts($checkoutItem: BankCheckoutQueryRequest) {
      bankingQueries {
        getBankCheckouts(checkoutItem: $checkoutItem) {
          ${items}
        }
      }
    }
  `
};

export const GET_BANK_ACCOUNTS = {
  routesNum: 2,
  gqlSchema: (items) => `
    query GetBankAccounts($bankAccountItem: BankAccountQueryRequest) {
      bankingQueries {
        getBankAccounts(bankAccountItem: $bankAccountItem) {
          ${items}
        }
      }
    }
  `
};

export const GET_BANK_MAIN_CURRENCY = {
  routesNum: 2,
  gqlSchema: (items) => `
    query GetBankMainCurrency($bankId: String) {
      bankingQueries {
        getBankMainCurrency(bankId: $bankId) {
          ${items}
        }
      }
    }
  `
};

export const GET_BRANCH_MAIN_CURRENCY = {
  routesNum: 2,
  gqlSchema: (items) => `
    query GetBranchMainCurrency($branchId: String) {
      branchManagementQueries {
        getBranchMainCurrency(branchId: $branchId) {
          ${items}
        }
      }
    }
  `
};

export const GET_RECEIVERS = {
  routesNum: 2,
  gqlSchema: (items) => `
    query GetReceivers($receiverItem: ReceiverQueryRequest) {
      identityInformationQueries {
        getReceivers(receiverItem: $receiverItem) {
          ${items}
        }
      }
    }
  `
};

export const GET_CUSTOMER = {
  routesNum: 2,
  gqlSchema: (items) => `
    query GetCustomer($customerId: String) {
      identityInformationQueries {
        getCustomer(customerId: $customerId) {
          ${items}
        }
      }
    }
  `
};

export const GET_PEOPLE_WITH_ACCOUNTS = {
  routesNum: 2,
  gqlSchema: (items) => `
    query GetPeopleWithAccounts($personItem: PersonQueryRequest) {
      accountingQueries {
        getPeopleWithAccounts(personItem: $personItem) {
          ${items}
        }
      }
    }
  `,
  customerType: {
    ALL: 'ALL',
    REAL_CUSTOMER: 'REAL_CUSTOMER',
    LEGAL_CUSTOMER: 'LEGAL_CUSTOMER'
  }
};

export const GET_BANK_BOOK_STATUS = {
  routesNum: 2,
  gqlSchema: (items) => `
    query GetBankBookStatus($bankStatusItem: BankBookStatusQueryRequest) {
      bankingQueries {
        getBankBookStatus(bankStatusItem: $bankStatusItem) {
          ${items}
        }
      }
    }
  `
};

export const GET_BANK_BOOK_TRANSACTIONS = {
  routesNum: 2,
  gqlSchema: (items) => `
    query GetBankBookTransactions($bankBookItem: BankBookQueryRequest) {
      bankingQueries {
        getBankBookTransactions(bankBookItem: $bankBookItem) {
          ${items}
        }
      }
    }
  `,
  documentType: {
    INTERBANK_TRANSACTION: 'InterBankTransaction',
    CHEQUE_TRANSACTION: 'ChequeTransaction',
    OUTGOING_DRAFT: 'OutgoingDraft',
    INCOMING_DRAFT: 'IncomingDraft',
    BANK_TRANSACTION: 'BankTransaction',
    TRADE_CURRENCY: 'TradeCurrency'
  }
};

export const GET_UNKNOWN_BANK_TRANSACTIONS = {
  routesNum: 2,
  gqlSchema: (items) => `
    query GetUnknownBankTransactions(
      $bankTransactionItem: BankDepositTransactionQueryRequest
    ) {
      bankingQueries {
        getUnknownBankTransactions(bankTransactionItem: $bankTransactionItem) {
          ${items}
        }
      }
    }
  `,
  transactionType: {
    ALL: 0,
    WITHDRAW: 1,
    DEPOSIT: 2
  }
};

export const GET_BRANCH_CHECKOUTS = {
  routesNum: 2,
  gqlSchema: (items) => `
    query GetBranchCheckouts($checkoutItem: BranchCheckoutQueryRequest) {
      branchManagementQueries {
        getBranchCheckouts(checkoutItem: $checkoutItem) {
          ${items}
        }
      }
    }
  `
};

export const GET_MIRROR_BRANCH_CHECKOUTS = {
  routesNum: 2,
  gqlSchema: (items) => `
    query GetMirrorBranchCheckouts($checkoutItem: MirrorBranchCheckoutQueryRequest) {
      branchManagementQueries {
        getMirrorBranchCheckouts(checkoutItem: $checkoutItem) {
          ${items}
        }
      }
    }
  `
};

export const GET_MIRROR_BRANCH_ID = {
  routesNum: 2,
  gqlSchema: () => `
    query GetMirrorBranchId($branchId: String) {
      branchManagementQueries {
        getMirrorBranchId(branchId: $branchId)
      }
    }
  `
};

export const GET_CUSTOMER_CHECKOUTS = {
  routesNum: 2,
  gqlSchema: (items) => `
    query GetCustomerCheckouts($checkoutItem: CustomerCheckoutQueryRequest) {
      accountingQueries {
        getCustomerCheckouts(checkoutItem: $checkoutItem) {
          ${items}
        }
      }
    }
  `
};

export const GET_BRANCH_OUTGOING_DRAFT_NUMBER = {
  routesNum: 2,
  gqlSchema: () => `
    query GetBranchOutgoingDraftNumber($branchId: String) {
      branchManagementQueries {
        getBranchOutgoingDraftNumber(branchId: $branchId)
      }
    }
  `
};

export const GET_BRANCH_INCOMING_DRAFT_NUMBER = {
  routesNum: 2,
  gqlSchema: () => `
    query GetBranchIncomingDraftNumber($branchId: String) {
      branchManagementQueries {
        getBranchIncomingDraftNumber(branchId: $branchId)
      }
    }
  `
};

export const GET_MIRROR_BRANCH_INCOMING_DRAFT_NUMBER = {
  routesNum: 2,
  gqlSchema: () => `
    query GetMirrorBranchIncomingDraftNumber($branchId: String) {
      branchManagementQueries {
        getMirrorBranchIncomingDraftNumber(branchId: $branchId)
      }
    }
  `
};

export const GET_ACCOUNT_INFORMATION = {
  routesNum: 2,
  gqlSchema: (items) => `
    query GetAccountInformation($accountInfoItem: CustomerAccountQueryRequest) {
      accountingQueries {
        getAccountInformation(accountInfoItem:$accountInfoItem) {
          ${items}
        }
      }
    }
  `
};

export const GET_BANK_ACCOUNT = {
  routesNum: 2,
  gqlSchema: (items) => `
    query GetBankAccount($bankAccountId: ID) {
      bankingQueries {
        getBankAccount(bankAccountId: $bankAccountId) {
          ${items}
        }
      }
    }
  `
};

export const GET_PERSON_BASIC_INFO = {
  routesNum: 2,
  gqlSchema: (items) => `
    query GetPersonBasicInfo($personId: String) {
      identityInformationQueries {
        getPersonBasicInfo(personId: $personId) {
          ${items}
        }
      }
    }
  `
};

export const GET_COMPANY_BASIC_INFO = {
  routesNum: 2,
  gqlSchema: (items) => `
    query GetCompanyBasicInfo($companyId: String) {
      identityInformationQueries {
        getCompanyBasicInfo(companyId: $companyId) {
          ${items}
        }
      }
    }
  `
};

export const GET_COMPANY_CONTACT_INFOS = {
  routesNum: 2,
  gqlSchema: (items) => `
    query GetContactInfos($personId: String) {
      identityInformationQueries {
        getContactInfos(personId: $personId) {
          ${items}
        }
      }
    }
  `
};

export const GET_PERSON_ADDRESSES = {
  routesNum: 2,
  gqlSchema: (items) => `
    query GetAddresses($personId: String) {
      identityInformationQueries {
        getAddresses(personId: $personId) {
          ${items}
        }
      }
    }
  `
};

export const GET_PERSON_ATTACHMENTS = {
  routesNum: 2,
  gqlSchema: (items) => `
    query GetAttachments($personId: String) {
      identityInformationQueries {
        getAttachments(personId: $personId) {
          ${items}
        }
      }
    }
  `
};

export const GET_PERSON_DOCUMENTS = {
  routesNum: 2,
  gqlSchema: (items) => `
    query GetDocuments($personId: String) {
      identityInformationQueries {
        getDocuments(personId: $personId) {
          ${items}
        }
      }
    }
  `
};

export const GET_PERSON_BANK_INFOS = {
  routesNum: 2,
  gqlSchema: (items) => `
    query GetBankInfos($personId: String) {
      identityInformationQueries {
        getBankInfos(personId: $personId) {
          ${items}
        }
      }
    }
  `
};

export const GET_PERSONAL_DETAILS = {
  routesNum: 2,
  gqlSchema: (items) => `
    query GetPersonalDetails($personId: String) {
      identityInformationQueries {
        getPersonalDetails(personId: $personId) {
          ${items}
        }
      }
    }
  `
};

export const GET_PERSON_ADDRESS = {
  routesNum: 2,
  gqlSchema: (items) => `
    query GetAddress($addressItem: AddressQueryRequest) {
      identityInformationQueries {
        getAddress(addressItem: $addressItem) {
          ${items}
        }
      }
    }
  `
};

export const GET_PERSON_DOCUMENT = {
  routesNum: 2,
  gqlSchema: (items) => `
    query GetDocument($documentItem: DocumentQueryRequest) {
      identityInformationQueries {
        getDocument(documentItem: $documentItem) {
          ${items}
        }
      }
    }
  `
};

export const GET_PERSON_BANK_INFO = {
  routesNum: 2,
  gqlSchema: (items) => `
    query GetBankInfo($bankInfoItem: BankInfoQueryRequest) {
      identityInformationQueries {
        getBankInfo(bankInfoItem: $bankInfoItem) {
          ${items}
        }
      }
    }
  `
};

export const GET_COMPANY_CONTACT_INFO = {
  routesNum: 2,
  gqlSchema: (items) => `
    query GetContactInfo($phoneInfo: ContactInfoQueryRequest) {
      identityInformationQueries {
        getContactInfo(phoneInfo: $phoneInfo) {
          ${items}
        }
      }
    }
  `
};

export const GET_CHEQUE_STATUSES = {
  routesNum: 2,
  gqlSchema: (items) => `
    query GetChequeStatuses($chequeStatusItem: ChequeDocumentStatusQueryRequest) {
      accountingQueries {
        getChequeStatuses(chequeStatusItem: $chequeStatusItem) {
          ${items}
        }
      }
    }
  `,
  documentStatus: {
    ALL: 'ALL',
    RECEIVED: 'RECEIVED',
    PAID: 'PAID'
  }
};

export const GET_CHEQUE_DOCUMENTS = {
  routesNum: 2,
  gqlSchema: (items) => `
    query GetChequeDocuments($chequeDocumentItem: ChequeQueryRequest) {
      accountingQueries {
        getChequeDocuments(chequeDocumentItem: $chequeDocumentItem) {
          ${items}
        }
      }
    }
  `,
  documentStatus: {
    ALL: 'ALL',
    RECEIVED: 'RECEIVED',
    PAID: 'PAID'
  }
};

export const GET_CHEQUE = {
  routesNum: 2,
  gqlSchema: (items) => `
    query GetCheque($documentNumber: String) {
      accountingQueries {
        getCheque(documentNumber: $documentNumber) {
          ${items}
        }
      }
    }
  `
};

export const GET_CHEQUE_CATEGORIES = {
  routesNum: 2,
  gqlSchema: (items) => `
    query GetChequeCategories($chequeCateogoriesItem: GetChequeCategoriesQueryRequest) {
      accountingQueries {
        getChequeCategories(chequeCateogoriesItem: $chequeCateogoriesItem) {
          ${items}
        }
      }
    }
  `
};

export const GET_ACCOUNTING_CHEQUE_HISTORY_STATUSES = {
  routesNum: 2,
  gqlSchema: (items) => `
    query GetChequeHistoryStatuses($accountNumber: String) {
      accountingQueries {
        getChequeHistoryStatuses(accountNumber: $accountNumber) {
          ${items}
        }
      }
    }
  `
};

export const GET_ACCOUNTING_CHEQUE_HISTORY_DOCUMENTS = {
  routesNum: 2,
  gqlSchema: (items) => `
    query GetChequeHistoryDocuments($accountItem: ChequeHistoryDocumentQueryRequest) {
      accountingQueries {
        getChequeHistoryDocuments(accountItem: $accountItem) {
          ${items}
        }
      }
    }
  `
};

export const GET_BANK_CHEQUE_HISTORY_STATUSES = {
  routesNum: 2,
  gqlSchema: (items) => `
    query GetChequeHistoryStatuses($bankAccountId: String) {
      bankingQueries {
        getChequeHistoryStatuses(bankAccountId: $bankAccountId) {
          ${items}
        }
      }
    }
  `
};

export const GET_BANK_CHEQUE_HISTORY_DOCUMENTS = {
  routesNum: 2,
  gqlSchema: (items) => `
    query GetChequeHistoryDocuments($chequeItem: ChequeHistoryInBankDocumentQueryRequest) {
      bankingQueries {
        getChequeHistoryDocuments(chequeItem: $chequeItem) {
          ${items}
        }
      }
    }
  `
};

export const GET_DETAILS_TRANSACTION = {
  routesNum: 2,
  gqlSchema: (items) => `
  query getDetailsTransaction($transactionId: String) {
    systemManagementQueries {
      getDetailsTransaction(transactionId: $transactionId) {
        ${items}
      }
    }
  }
  `
};

export const GET_SHAREHOLDERS = {
  routesNum: 2,
  gqlSchema: (items) => `
  query GetShareholders($personId: String) {
    identityInformationQueries {
      getShareholders(personId: $personId) {
          ${items}
        }
      }
    }
  `
};

export const GET_SHAREHOLDER = {
  routesNum: 2,
  gqlSchema: (items) => `
    query GetShareholder($shareHolderItem: GerPersonShareholderQueryRequest) {
      identityInformationQueries {
        getShareholder(shareHolderItem: $shareHolderItem) {
          ${items}
        }
      }
    }
  `
};

export const GENERATE_ACCOUNT_NUMBER = {
  routesNum: 2,
  gqlSchema: () => `
    query GenerateAccountNumber {
      accountingQueries {
        generateAccountNumber
      }
    }
  `
};

export const GET_CURRENCY_CALCULATIONS = {
  routesNum: 2,
  gqlSchema: (items) => `
    query GetCurrencyCalculations(
      $currencyCalculationItem: CurrencyCalculationQueryRequest
    ) {
      systemManagementQueries {
        getCurrencyCalculations(
          currencyCalculationItem: $currencyCalculationItem
        ) {
          ${items}
        }
      }
    }
  `
};

export const GET_BANK_BOOK_DOCUMENT_TYPES = {
  routesNum: 2,
  gqlSchema: (items) => `
    query GetBankBookDocumentTypes {
      bankingQueries {
        getBankBookDocumentTypes {
          ${items}
        }
      }
    }
  `
};

export const GET_ACCOUNT_DOCUMENT_TYPES = {
  routesNum: 2,
  gqlSchema: (items) => `
    query GetAccountDocumentTypes {
      accountingQueries {
        getAccountDocumentTypes {
          ${items}
        }
      }
    }
  `
};

export const GET_INTERBANK_TRANSACTION = {
  routesNum: 2,
  gqlSchema: (items) => `
    query GetInterBankTransaction($interBankTransactionId: String) {
      bankingQueries {
        getInterBankTransaction(interBankTransactionId: $interBankTransactionId) {
          ${items}
        }
      }
    }
  `
};

export const GET_STATE = {
  routesNum: 2,
  gqlSchema: (items) => `
    query GetState($stateStatus: SagaStateRequest) {
      getStateGroupQuery {
        getState(stateStatus: $stateStatus) {
          ${items}
        }
      }
    }
  `,
  type: {
    IDENTITY_INFORMATION: 'IDENTITY_INFORMATION',
    ACCOUNTING: 'ACCOUNTING',
    BANK: 'BANK',
    BRANCH_MANAGEMENT: 'BRANCH_MANAGEMENT',
    STATIC_DATA: 'STATIC_DATA',
    PAYMENT_ORDER: 'PAYMENT_ORDER',
    TRADE_CURRENCY: 'TRADE_CURRENCY',
    TICKETING: 'TICKETING',
    CREDIT_MANAGEMENT: 'CREDIT_MANAGEMENT',
    TPM: 'TPM',
    ARCHIVE: 'ARCHIVE',
    GARDESHGARI: 'GARDESHGARI'
  },
  status: {
    FAILED: 'Failed',
    PENDING: 'Pending',
    SUCCEED: 'Succeed'
  }
};

export const GET_SELL_REPORTS = {
  routesNum: 2,
  gqlSchema: (items) => `
  query GetSellsReports($sellsItem : SellReportQueryRequest ) {
    rechargeGroupQueries {
      getSellsReports(sellsItem : $sellsItem) {
        ${items}
      }
    }
  }
  `
};

export const GET_PAYMENT_PLACES = {
  routesNum: 2,
  gqlSchema: (items) => `
  query GetPaymentPlaces($paymentPlaceItem : GetPaymentPlaceRequest ) {
    rechargeGroupQueries {
      getPaymentPlaces(paymentPlaceItem : $paymentPlaceItem) {
        ${items}
      }
    }
  }
  `
};

export const GET_PAYMENT_PLACE = {
  routesNum: 2,
  gqlSchema: (items) => `
  query GetPaymentPlace($getPaymentPlaceQueryRequest : GetPaymentPlaceQueryRequest ) {
    branchManagementQueries {
      getPaymentPlace(getPaymentPlaceQueryRequest : $getPaymentPlaceQueryRequest) {
        ${items}
      }
    }
  }
  `
};

export const GET_PAYMENT_PLACES_BRANCH = {
  routesNum: 2,
  gqlSchema: (items) => `
  query GetPaymentPlaces($searchText : String ) {
    branchManagementQueries {
      getPaymentPlaces(searchText : $searchText) {
        ${items}
      }
    }
  }
  `
};

export const GET_ACCOUNTS = {
  routesNum: 2,
  gqlSchema: (items) => `
    query GetAccounts($accountItem : CustomerFlatQueryRequest ) {
      accountingQueries {
        getAccounts(accountItem : $accountItem) {
          ${items}
        }
      }
    }
  `,
  accountType: {
    ALL: 'ALL_ACCOUNTS',
    ALL_CAN_TRANSACT: 'ALL_CAN_TRANSACT',
    JUST_CASH: 'JUST_CASH',
    JUST_UNKNOWN_BANK_ACCOUNT: 'JUST_UNKNOWN_BANK_ACCOUNT',
    JUST_UNEXECUTED_DRAFT_ACCOUNT: 'JUST_UNEXECUTED_DRAFT_ACCOUNT',
    SYSTEM_ACCOUNTS: 'SYSTEM_ACCOUNTS',
    JUST_CUSTOMER_ACCOUNTS: 'JUST_CUSTOMER_ACCOUNTS',
    JUST_NORMAL_ACCOUNTS: 'JUST_NORMAL_ACCOUNTS',
    ALL_CAN_BE_CREDITOR: 'ALL_CAN_BE_CREDITOR',
    CASH_NORMAL_ACCOUNTS: 'CASH_NORMAL_ACCOUNTS',
    MUTUAL_ACCOUNTS: 'MUTUAL_ACCOUNTS'
  },
  typeTitle: {
    NORMAL: 'Normal',
    CASH: 'Cash',
    UNREALIZED_DRAFT: 'UnrealizedDraft',
    CHARGING_SALE: 'ChargingSale',
    COSTS: 'Costs',
    UNKNOWN_BANK_ACCOUNTS: 'UnknownBankAccounts',
    UNEXECUTED_DRAFT: 'UnexecutedDraft'
  }
};

export const GET_CHARGE_ACCOUNTS = {
  routesNum: 2,
  gqlSchema: (items) => `
  query GetAccounts($accountsItem : GetAccountsRequest ) {
    rechargeGroupQueries {
      getAccounts(accountsItem : $accountsItem) {
        ${items}
      }
    }
  }
  `
};

export const GET_CHARGE_ACCOUNT = {
  routesNum: 2,
  gqlSchema: (items) => `
  query GetAccount($accountId : Int ) {
    rechargeGroupQueries {
      getAccount(accountId : $accountId) {
        ${items}
      }
    }
  }
  `
};

export const GET_ACCOUNT = {
  routesNum: 2,
  gqlSchema: (items) => `
  query GetAccount($accountNumber: String) {
    accountingQueries {
      getAccount(accountNumber: $accountNumber) {
        ${items}
      }
    }
  }
  `
};

export const GET_EXCHANGER_RATES = {
  routesNum: 2,
  gqlSchema: (items) => `
  query GetExchangerRates($rateItem : GetExchangerRateRequest ) {
    rechargeGroupQueries {
      getExchangerRates(rateItem : $rateItem) {
        ${items}
      }
    }
  }
  `
};

export const GET_EXCHANGER_RATE = {
  routesNum: 2,
  gqlSchema: (items) => `
  query GetExchangerRate($exchangerRateId : Int ) {
    rechargeGroupQueries {
      getExchangerRate(exchangerRateId : $exchangerRateId) {
        ${items}
      }
    }
  }
  `
};

export const GET_PACKAGES = {
  routesNum: 2,
  gqlSchema: (items) => `
  query GetPackages($packageItem : PackageQueryRequest ) {
    rechargeGroupQueries {
      getPackages(packageItem : $packageItem) {
        ${items}
      }
    }
  }
  `
};

export const GET_CUSTOMER_ALL_ACCOUNTS_STATUS = {
  routesNum: 2,
  gqlSchema: (items) => `
    query GetCustomerAllAccountsStatus($customerId: String) {
      accountingQueries {
        getCustomerAllAccountsStatus(customerId: $customerId) {
          ${items}
        }
      }
    }
  `
};

export const GET_CUSTOMER_ACCOUNTS = {
  routesNum: 2,
  gqlSchema: (items) => `
    query GetCustomerAccounts($customerId: String!) {
      accountingQueries {
        getCustomerAccounts(customerId: $customerId) {
          ${items}
        }
      }
    }
  `
};

export const GET_SYSTEM_ACCOUNT = {
  routesNum: 2,
  gqlSchema: (items) => `
    query GetSystemAccount($accountNumber: String) {
      accountingQueries {
        getSystemAccount(accountNumber: $accountNumber) {
          ${items}
        }
      }
    }
  `
};

export const GET_SYSTEM_SETTINGS = {
  routesNum: 2,
  gqlSchema: (items) => `
    query GetSystemSettings {
      systemManagementQueries {
        getSystemSettings {
          ${items}
        }
      }
    }
  `
};

export const GET_PROFIT_AND_LOSS_AMOUNTS = {
  routesNum: 2,
  gqlSchema: (items) => `
    query GetProfitAndLossAmounts {
      overallReportsQueries {
        getProfitAndLossAmounts {
          ${items}
        }
      }
    }
  `
};

export const GET_DESCRIPTION_TEMPLATES = {
  routesNum: 2,
  gqlSchema: (items) => `
    query GetDescriptionTemplates($language: String) {
      systemManagementQueries {
        getDescriptionTemplates(language: $language) {
          ${items}
        }
      }
    }
  `
};

export const GET_ACTION_LOGS = {
  routesNum: 2,
  gqlSchema: (items) => `
    query GetActionLogs($actionLogItem: GetActionLogQueryRequest) {
      systemManagementQueries {
        getActionLogs(actionLogItem: $actionLogItem) {
          ${items}
        }
      }
    }
  `
};

export const GET_ACTION_TYPES = {
  routesNum: 2,
  gqlSchema: (items) => `
    query GetActionTypes {
      systemManagementQueries {
        getActionTypes {
          ${items}
        }
      }
    }
  `
};

export const GET_TAXONOMY = {
  routesNum: 2,
  gqlSchema: (items) => `
  query getTaxonomy($enumType: EnumType){
    systemManagementQueries{
      getTaxonomy(enumType: $enumType){
        ${items}
      }
    }
  }
  `,
  enumType: {
    ADMIN_COMMISSION_TRANSACTION_TYPE: 'AdminCommissionTransactionType',
    EXCHANGER_COMMISSION_TRANSACTION_TYPE: 'ExchangerCommissionTransactionType',
    ACCOUNT_DOCUMENT_TYPE: 'AccountDocumentType'
  }
};

export const GET_PERSON_PROFILE = {
  routesNum: 2,
  gqlSchema: (items) => `
    query GetPersonProfile($personId: ID){
      identityInformationQueries {
        getPersonProfile(personId: $personId) {
          ${items}
        }
      }
    }
  `
};

export const GET_CHEQUE_STATUSES_REPORT = {
  routesNum: 2,
  gqlSchema: (items) => `
    query GetChequeStatusesReport{
      overallReportsQueries {
        getChequeStatusesReport {
          ${items}
      }
    }
  }
`
};

export const GET_PAYMENT_ORDERS = {
  routesNum: 2,
  gqlSchema: (items) => `
  query GetPaymentOrders($paymentOrderItem: PaymentOrderQueryRequest) {
    accountingQueries {
      getPaymentOrders(paymentOrderItem: $paymentOrderItem) {
          ${items}
        }
      }
    }
  `
};

export const GET_BRANCH_INFO = {
  routesNum: 2,
  gqlSchema: (items) => `
  query GetBranchInfo($branchId: ID) {
    branchManagementQueries {
      getBranchInfo(branchId: $branchId) {
          ${items}
        }
      }
    }
  `
};

export const GET_BRANCH_FIRST_STEP = {
  routesNum: 2,
  gqlSchema: (items) => `
  query GetBranchFirstStep($branchId: String) {
    branchManagementQueries {
      getBranchFirstStep(branchId: $branchId) {
        ${items}
      }
    }
  }
  `
};

export const GET_ACTIVE_PHONE_BY_ACCOUNT_ID = {
  routesNum: 2,
  gqlSchema: (items) => `
  query GetActivePhoneByAccountId($accountNumber: String) {
    accountingQueries {
      getActivePhoneByAccountId(accountNumber: $accountNumber) {
        ${items}
      }
    }
  }
  `
};

export const GET_BRANCH_SECOND_STEP = {
  routesNum: 2,
  gqlSchema: (items) => `
  query GetBranchSecondStep($branchId: String) {
    branchManagementQueries {
      getBranchSecondStep(branchId: $branchId) {
        ${items}
      }
    }
  }
  `
};

export const GET_BRANCH_THIRD_STEP = {
  routesNum: 2,
  gqlSchema: (items) => `
  query GetBranchThirdStep($branchId: String!) {
    branchManagementQueries {
      getBranchThirdStep(branchId: $branchId) {
        ${items}
      }
    }
  }
  `
};
export const GET_BRANCH_PAYMENT_PLACES = {
  routesNum: 2,
  gqlSchema: (items) => `
  query GetBranchPaymentPlaces($requestItem: GetBranchPaymentPlacesQueryRequest) {
    branchManagementQueries {
      getBranchPaymentPlaces(requestItem: $requestItem) {
          ${items}
      }
    }
  }
  `
};
export const GET_MOST_DEBTORS = {
  routesNum: 2,
  gqlSchema: (items) => `
  query GetMostDebtors {
    overallReportsQueries {
      getMostDebtors {
          ${items}
        }
      }
    }
  `
};

export const GET_COMMISSION_REPORT_STATUS_DETAILS = {
  routesNum: 2,
  gqlSchema: (items) =>
    `  query GetCommissionReportStatusDetails($getCommissionStatusDetailRequestItem : GetCommissionStatusDetailRequestItem) {
    overallReportsQueries {
      getCommissionReportStatusDetails (getCommissionStatusDetailRequestItem : $getCommissionStatusDetailRequestItem) {
              ${items}
            }
          }
        }`
};
export const GET_DAILY_REPORT = {
  routesNum: 2,
  gqlSchema: (items) => `
    query GetDailyReport($getDailyReportItem: GetReportQueryRequest) {
      overallReportsQueries {
        getDailyReport(getDailyReportItem: $getDailyReportItem) {
          ${items}
        }
      }
    }
  `,
  inputCalendarTypes: {
    GREGORIAN: 'GREGORIAN',
    JALALI: 'PERSIAN'
  }
};

export const GET_PAYMENT_ORDER = {
  routesNum: 2,
  gqlSchema: (items) => `
  query GetSelectedPaymentOrder($paymentOrderId:ID) {
    accountingQueries {
      getSelectedPaymentOrder(paymentOrderId: $paymentOrderId) {
          ${items}
        }
      }
    }
  `
};

export const GET_MONTHLY_REPORT = {
  routesNum: 2,
  gqlSchema: (items) => `
    query GetMonthlyReport($getMonthlyReportItem: GetReportQueryRequest) {
      overallReportsQueries {
        getMonthlyReport(getMonthlyReportItem: $getMonthlyReportItem) {
          ${items}
        }
      }
    }
  `,
  calendarTypes: {
    JALALI: 'PersianSnapshot',
    GREGORIAN: 'GregorianSnapshot'
  },
  inputCalendarTypes: {
    GREGORIAN: 'GREGORIAN',
    JALALI: 'PERSIAN'
  }
};

export const GET_PAYMENT_RECEIPTS = {
  routesNum: 2,
  gqlSchema: (items) => `
  query GetPaymentReceipts($paymentOrderId:ID) {
    accountingQueries {
      getPaymentReceipts(paymentOrderId: $paymentOrderId) {
          ${items}
        }
      }
    }
  `
};

export const GET_DEBTORS_AND_CREDITORS_STATUS = {
  routesNum: 2,
  gqlSchema: (items) => `
  query GetDebtorsAndCreditorsStatus($debtorsAndCreditorsItem: GetDebtorsAndCreditorsAccountsQueryRequest) {
    overallReportsQueries {
      getDebtorsAndCreditorsStatus(debtorsAndCreditorsItem: $debtorsAndCreditorsItem) {
        ${items}
      }
    }
  }
  `
};

export const GET_COMMISSIONS_REPORT_STATUS = {
  routesNum: 2,
  gqlSchema: (items) => `
  query getCommissionsReportStatus($getCommissionsReportStatusRequestItem: GetCommissionsReportStatusRequestItem){
    overallReportsQueries{
      getCommissionsReportStatus(getCommissionsReportStatusRequestItem:$getCommissionsReportStatusRequestItem){
        ${items}
      }
    }
  }
  `
};
export const GET_BRANCH_CONNECTION_TOKEN = {
  routesNum: 2,
  gqlSchema: () => `
    query GetBranchConnectionToken($branchId: String!) {
      branchManagementQueries {
        getBranchConnectionToken(branchId: $branchId)
      }
    }
  `
};

export const GET_TRADE_CURRENCIES = {
  routesNum: 2,
  gqlSchema: (items) => `
    query GetTradeCurrencies($currencyTradeItem: GetTradeCurrencyRequest) {
      accountingQueries {
        getTradeCurrencies(currencyTradeItem: $currencyTradeItem) {
          ${items}
        }
      }
    }
  `
};

export const GET_TRADE_CURRENCY_STATUS = {
  routesNum: 2,
  gqlSchema: (items) => `
    query GetTradeCurrencyStatus($tradeCurrencyStatusItem: TradeCurrencyStatusArgs) {
      accountingQueries {
        getTradeCurrencyStatus(tradeCurrencyStatusItem: $tradeCurrencyStatusItem) {
          ${items}
        }
      }
    }
  `
};

export const GET_MIGRATION_LIST = {
  routesNum: 2,
  gqlSchema: (items) => `
    query GetMigrationList($migrationItem: GetMigrationListRequest) {
      migrationQueries {
        getMigrationList(migrationItem: $migrationItem) {
          ${items}
        }
      }
    }
  `
};

export const GET_DEBTORS_AND_CREDITORS_ACCOUNTS = {
  routesNum: 2,
  gqlSchema: (items) => `
  query GetDebtorsAndCreditorsAccounts($debtorsAndCreditorsItem: GetDebtorsAndCreditorsAccountsQueryRequest) {
    overallReportsQueries {
      getDebtorsAndCreditorsAccounts(debtorsAndCreditorsItem: $debtorsAndCreditorsItem) {
        ${items}
      }
    }
  }
  `
};

export const GET_COMMISSIONS_REPORT_DOCUMENTS = {
  routesNum: 2,
  gqlSchema: (items) => `
    query getCommissionsReportDocuments($getCommissionsReportDocumentsRequestItem:GetCommissionsReportDocumentsRequestItem){
      overallReportsQueries{
        getCommissionsReportDocuments(getCommissionsReportDocumentsRequestItem:$getCommissionsReportDocumentsRequestItem){
          ${items}
        }
      }
    }
  `
};

export const GET_TICKETS_FOR_EXCHANGER = {
  routesNum: 2,
  gqlSchema: (items) => `
  query GetTicketsForExchanger($ticketItem: GetTicketsRequest) {
    supportQueries {
      getTicketsForExchanger(ticketItem: $ticketItem) {
        ${items}
      }
    }
  }
  `,
  ticketStatus: {
    ALL: 'ALL',
    NEW: 'NEW',
    IN_PROGRESS: 'IN_PROGRESS',
    DONE: 'DONE',
    REJECTED: 'REJECTED'
  }
};
export const GET_TICKETS_FOR_SUPPORTER = {
  routesNum: 2,
  gqlSchema: (items) => `
  query GetTicketsForSupporter($ticketItem: GetTicketsForSupporterRequest) {
    supportQueries {
      getTicketsForSupporter(ticketItem: $ticketItem) {
        ${items}
      }
    }
  }
  `,
  ticketStatus: {
    ALL: 'ALL',
    NEW: 'NEW',
    IN_PROGRESS: 'IN_PROGRESS',
    DONE: 'DONE',
    REJECTED: 'REJECTED'
  }
};

export const GET_TICKETS_FOR_DEVELOPER = {
  routesNum: 2,
  gqlSchema: (items) => `
  query GetTicketsForDeveloper($ticketItem: GetTicketsForDeveloperRequest) {
    supportQueries {
      getTicketsForDeveloper(ticketItem: $ticketItem) {
        ${items}
      }
    }
  }
  `,
  ticketStatus: {
    ALL: 'ALL',
    NEW: 'NEW',
    IN_PROGRESS: 'IN_PROGRESS',
    DONE: 'DONE',
    REJECTED: 'REJECTED'
  }
};

export const GET_REGISTERED_TICKETS = {
  routesNum: 2,
  gqlSchema: (items) => `
  query GetRegisteredTickets($getTicketsItem: GetRegiserTicketsRequest) {
    supportQueries {
      getRegisteredTickets(getTicketsItem: $getTicketsItem) {
        ${items}
      }
    }
  }
  `
};
export const GET_TICKET_INFO = {
  routesNum: 2,
  gqlSchema: (items) => `
  query GetTicketInfo($threadId: Int) {
    supportQueries {
      getTicketInfo(threadId: $threadId) {
        ${items}
      }
    }
  }
  `
};

export const IS_USERNAME_VALID = {
  routesNum: 2,
  gqlSchema: () => `
  query IsUserNameValid($userNameValidationItem: GetUserNameValidationQueryRequest){
    identityInformationQueries {
      isUserNameValid(userNameValidationItem: $userNameValidationItem)
    }
  }
  `
};

export const IS_CONNECTION_STRING_VALID = {
  routesNum: 2,
  gqlSchema: () => `
  query IsConnectionStringValid($connectionString: String){
    migrationQueries {
      isConnectionStringValid(connectionString: $connectionString)
    }
  }
  `
};

export const GET_COMMUNICATION_WAYS = {
  routesNum: 2,
  gqlSchema: (items) => `
    query GetCommunicationWays {
      systemManagementQueries {
        getCommunicationWays {
          ${items}
        }
      }
    }
  `
};

export const GET_GROUPS = {
  routesNum: 2,
  gqlSchema: (items) => `
    query GetGroups {
      systemManagementQueries {
        getGroups {
          ${items}
        }
      }
    }
  `
};

export const GET_COUNTRIES = {
  routesNum: 2,
  gqlSchema: (items) => `
    query GetCountries($getCountriesRequest: GetCountriesQueryRequest) {
      systemManagementQueries {
        getCountries(getCountriesRequest: $getCountriesRequest) {
          ${items}
        }
      }
    }
  `
};

export const GET_SYSTEM_CURRENCIES = {
  routesNum: 2,
  gqlSchema: (items) => `
  query GetSystemCurrencies($currencyItem: CurrencyManagementQueryRequest) {
    systemManagementQueries {
      getSystemCurrencies(currencyItem: $currencyItem) {
        ${items}
      }
    }
  }
  `
};

export const GET_ACCOUNTING_TYPES = {
  routesNum: 2,
  gqlSchema: (items) => `
    query GetAccountingTypes {
      systemManagementQueries {
        getAccountingTypes {
          ${items}
        }
      }
    }
  `
};

export const GET_SYSTEM_ACCOUNT_TYPES = {
  routesNum: 2,
  gqlSchema: (items) => `
    query GetSystemAccountTypes($accountType: Int) {
      accountingQueries {
        getSystemAccountTypes(accountType: $accountType) {
          ${items}
        }
      }
    }
  `
};

export const GET_BANK_CHECKOUTS = {
  routesNum: 2,
  gqlSchema: (items) => `
    query GetBankCheckouts($checkoutItem: BankCheckoutQueryRequest) {
      bankingQueries {
        getBankCheckouts(checkoutItem: $checkoutItem) {
          ${items}
        }
      }
    }
  `
};

export const GET_CUSTOMER_DRAFT_RECEIVERS = {
  routesNum: 2,
  gqlSchema: (items) => `
    query GetCustomerDraftReceivers(
      $draftReceiverItem: CustomerDraftReceiverQueryRequest
    ) {
      branchManagementQueries {
        getCustomerDraftReceivers(draftReceiverItem: $draftReceiverItem) {
          ${items}
        }
      }
    }
  `
};

export const GET_CREDIT_ACCOUNT_INFORMATION = {
  routesNum: 2,
  gqlSchema: (items) => `
    query GetCreditAccountInformation {
      creditManagementQueries {
        getCreditAccountInformation {
          ${items}
        }
      }
    }
  `
};

export const GET_CREDIT_ACCOUNT_INFORMATION_BY_ADMIN = {
  routesNum: 2,
  gqlSchema: (items) => `
    query GetCreditAccountInformationByAdmin($accountId: String) {
      creditManagementQueries {
        getCreditAccountInformationByAdmin(accountId: $accountId) {
          ${items}
        }
      }
    }
  `
};

export const GET_CREDIT_ACCOUNT_TRANSACTIONS = {
  routesNum: 2,
  gqlSchema: (items) => `
    query GetCreditAccountTransactions($getCreditTransactionRequestInput: GetCreditAccountTransactionsRequest) {
      creditManagementQueries {
        getCreditAccountTransactions(getCreditTransactionRequestInput: $getCreditTransactionRequestInput) {
          ${items}
        }
      }
    }
  `,
  documentType: {
    MOBILE: 0,
    UNKNOWN: 1,
    KNOWN: 2
  }
};

export const GET_CREDIT_ACCOUNT_TRANSACTIONS_BY_ADMIN = {
  routesNum: 2,
  gqlSchema: (items) => `
    query GetCreditAccountTransactionsByAdmin($getCreditAccountTransactionsByAdminItem: GetCreditAccountTransactionsByAdminRequest) {
      creditManagementQueries {
        getCreditAccountTransactionsByAdmin(getCreditAccountTransactionsByAdminItem: $getCreditAccountTransactionsByAdminItem) {
          ${items}
        }
      }
    }
  `
};

export const GET_CREDIT_ACCOUNTS = {
  routesNum: 2,
  gqlSchema: (items) => `
    query GetCreditAccounts($getCreditAccountsRequestItem: GetCreditAccountsRequest) {
      creditManagementQueries {
        getCreditAccounts(getCreditAccountsRequestItem: $getCreditAccountsRequestItem) {
          ${items}
        }
      }
    }
  `
};

export const GET_TICKET_TRACKING = {
  routesNum: 2,
  gqlSchema: (items) => `
    query GetTicketTracking($getTicketTrackingQueryItem: GetTicketTrackingRequest) {
      supportQueries {
        getTicketTracking(getTicketTrackingQueryItem: $getTicketTrackingQueryItem) {
          ${items}
        }
      }
    }
  `
};

export const GET_SERVICE_PROVIDERS = {
  routesNum: 2,
  gqlSchema: (items) => `
    query GetServiceProviders {
      tpmQueries {
        getServiceProviders {
          ${items}
        }
      }
    }
  `
};

export const GET_SERVICE_PROVIDER_DOCUMENTS = {
  routesNum: 2,
  gqlSchema: (items) => `
  query GetServiceProviders($getServiceProviderDocumentsRequestItem : GetServiceProviderDocumentsRequest) {
          tpmQueries {
            getServiceProviderDocuments (getServiceProviderDocumentsRequestItem : $getServiceProviderDocumentsRequestItem) {
            ${items}
          }
        }
      }
  `
};

export const GET_SERVICE_PROVIDER_INFORMATION = {
  routesNum: 2,
  gqlSchema: (items) => `
  query GetServiceProviderInformations($getServiceProviderInformationsRequestItem : String) {
          tpmQueries {
            getServiceProviderInformations (getServiceProviderInformationsRequestItem : $getServiceProviderInformationsRequestItem) {
              ${items}
            }
          }
        }
  `
};
export const GET_REPORT_MIN_AND_MAX_DATE = {
  routesNum: 2,
  gqlSchema: (items) => `
  query GetReportMinAndMaxDate {
    overallReportsQueries {
      getReportMinAndMaxDate {
        ${items}
      }
  }
  }
  `
};

export const EXPORT_EXCEL_DATA_VALIDATION = {
  routesNum: 2,
  gqlSchema: () => `
  query getExportExcelFileValidation{
    overallReportsQueries{
      isExportDataValid
    }
  }
  `
};

export const GET_EXCEL_FROM_HISTORY = {
  routesNum: 2,
  gqlSchema: (items) => `
  query getExportExcelFileFromHistory{
    overallReportsQueries{
      getExportDataFromHistory{
        ${items}
      }
    }
  }
  `
};

export const IS_WHATSAPP_CLIENT_INITIALIZED = {
  routesNum: 2,
  gqlSchema: () => `
    query IsWhatsappClientInitialized {
      whatsappQueries {
        isWhatsappClientInitialized
      }
    }
  `
};

export const GENERATE_WHATSAPP_INITIALIZATION_QR_CODE = {
  routesNum: 2,
  gqlSchema: () => `
    query generateQrCode{
      whatsappQueries{
        generateWhatsappInitializationQRCode{
          qrCode,
          expireTime
        }
      }
    }
  `
};
export const CHECK_WHATSAPP_LOGGIN_STATE = {
  routesNum: 2,
  gqlSchema: () => `
  query WhatsAppLoginValidation{
    whatsappQueries{
      isWhatsappClientInitialized{
        isValid
      }
    }
  }
  `
};

export const GET_TICKET_OWNER_MANAGER_ID = {
  routesNum: 2,
  gqlSchema: () => `
  query getOwnerTicketsTest{
    supportQueries{
      getOwnerTickets{
        ownerManagerIds
      }
    }
  }
  `
};

export const GET_SALE_RECHARGE_REPORT = {
  routesNum: 2,
  gqlSchema: (items) => `
    query getSaleRechargeReport ($sellRequest: GetSellReportRequest) {
      tpmQueries {
        getSaleRechargeReport (sellRequest: $sellRequest) {
          ${items}
        }
      }
    }
  `
};

export const GET_TRANSACTIONS_STATUS = {
  routesNum: 2,
  gqlSchema: (items) => `
    query getTransactionsStatus ($searchItem: RechargeTransactionStatusRequest) {
      tpmQueries {
        getTransactionsStatus (searchItem: $searchItem) {
          ${items}
        }
      }
    }
  `
};

export const GET_FEATURE_NAMES_LIST = {
  routesNum: 2,
  gqlSchema: (items) => `
  query getFeatureNamesList {
    tpmQueries {
      getFeatures {
          ${items}
        }
      }
    }
  `
};

export const GET_OPERATOR_NAMES_LIST = {
  routesNum: 2,
  gqlSchema: (items) => `
  query GetOperatorNamesList {
    tpmQueries {
      getOperators {
          ${items}
        }
      }
    }
  `
};

export const GET_OPERATOR_WITH_FEATURE_ID = {
  routesNum: 2,
  gqlSchema: (items) => `
    query GetOperatorsWithFeatureId ($featureId: Int!) {
      tpmQueries {
        getOperatorsWithFeatureId (featureId: $featureId) {
            ${items}
          }
        }
      }
  `
};

export const GET_SERVICE_PROVIDER_NAMES_LIST = {
  routesNum: 2,
  gqlSchema: (items) => `
  query GetServiceProviderNamesList {
    tpmQueries {
      getServiceProviders {
          ${items}
        }
      }
    }
  `
};

export const GET_SERVICE_PROVIDERS_WITH_FEATURE_ID = {
  routesNum: 2,
  gqlSchema: (items) => `
    query GetServiceProvidersWithFeatureId ($featureId: Int!) {
      tpmQueries {
        getServiceProvidersWithFeatureId (featureId: $featureId) {
          ${items}
        }
      }
    }
  `
};

export const GET_COUNTRY_CODE_NAMES_LIST = {
  routesNum: 2,
  gqlSchema: () => `
  query getCountryCodeNamesList {
    tpmQueries {
      getCountries
      }
    }
  `
};

export const GET_CURRENCY_RATES = {
  routesNum: 2,
  gqlSchema: (items) => `
  query getCurrencyRates {
    tpmQueries {
      getCurrencyRates {
        ${items}
        }
      }
    }
  `
};

export const GET_PACKAGES_FROM_FEATURES = {
  routesNum: 2,
  gqlSchema: (items) => `
  query getPackagesFromFeature ($searchItem: GetPackageRequest!) {
    tpmQueries {
      getPackagesFromFeature (searchItem: $searchItem) {
        ${items}
        }
      }
    }
  `
};

export const GET_PACKAGE_BY_ID = {
  routesNum: 2,
  gqlSchema: (items) => `
  query getPackageById ($packageId: Int!) {
    tpmQueries {
      getPackageById (packageId: $packageId) {
        ${items}
        }
      }
    }
  `
};

export const GET_DAILY_SALE_REPORT = {
  routesNum: 2,
  gqlSchema: (items) => `
  query GetDailySalesReport ($searchItem: GetDailySalesReportRequest) {
    tpmQueries {
      getDailySalesReport (searchItem: $searchItem) {
        ${items}
        }
      }
    }
  `
};
export const GET_GARDESHGARI_BON_CARD_LIST_REQUESTS = {
  routesNum: 2,
  gqlSchema: (items) => `
  query getCustomerRegistrationRequests ($getCustomerRegistrationRequestsArgs : GetCustomerRegistrationRequestsArgs) {
    gardeshgariQueries {
      getCustomerRegistrationRequests (getCustomerRegistrationRequestsArgs: $getCustomerRegistrationRequestsArgs) {
        ${items}
      }
    }
  }
  `,
  requestStatus: {
    SupportPend: 'SupportPend',
    BankPend: 'BankPend',
    SupportFail: 'SupportFail',
    BankFail: 'BankFail',
    Done: 'Done'
  },
  requestState: {
    All: 'All',
    Failed: 'Failed',
    Pend: 'Pend',
    Success: 'Success'
  }
};

export const GET_CUSTOMER_REGISTRATION_REQUEST = {
  routesNum: 2,
  gqlSchema: (items) => `
    query getCustomerRegistrationRequest($requestId: String!){
      gardeshgariQueries {
        getCustomerRegistrationRequest(requestId: $requestId) {
          ${items}
        }
      }
    }
  `
};

export const GET_DETAIL_PACKAGE = {
  routesNum: 2,
  gqlSchema: (items) => `
    query getDetailPackage($packageId: Int) {
      tpmQueries {
        getDetailPackage(packageId: $packageId) {
          ${items}
        }
      }
    }
  `
};

export const GET_BANK_UPDATE_STATUS = {
  routesNum: 2,
  gqlSchema: () => `
    query isBankcalculating ($bankAccountId:String) {
      bankingQueries{
        getBankUpdateStatus(bankAccountId: $bankAccountId){
          isDone
        }
      }
    }
  `
};

export const GET_ACCOUNT_UPDATE_STATUS = {
  routesNum: 2,
  gqlSchema: () => `
    query isAccountcalculating ($accountId:String) {
      accountingQueries{
        getAccountUpdateStatus(accountId: $accountId){
          isDone
        }
      }
    }
  `
};

export const GET_CURRENCY_PAYMENT_PLACE_RATES = {
  routesNum: 2,
  gqlSchema: (items) => `
    query getCurrencyPaymentPlaceRates ($getCurrencyPaymentPlaceRatesRequest: GetCurrencyPaymentPlaceRatesRequest) {
      branchManagementQueries {
        getCurrencyPaymentPlaceRates(getCurrencyPaymentPlaceRatesRequest: $getCurrencyPaymentPlaceRatesRequest) {
          ${items}
        }
      }
    }
  `
};
export const GET_BRANCH_UPDATE_STATUS = {
  routesNum: 2,
  gqlSchema: () => `
    query isBranchcalculating ($branchId:String) {
      branchManagementQueries{
        getBranchUpdateStatus(branchId: $branchId)
      }
    }
  `
};
export const GET_RATE_EXPIRE_DATE = {
  routesNum: 2,
  gqlSchema: () => `
    query getRateExpireDate  {
      systemManagementQueries {
        getRateExpireDate
      }
    }
  `
};

// *********************** end queries ******************************
// *********************** mutations ******************************

export const SUBMIT_CASH_TRANSACTION = {
  routesNum: 2,
  gqlSchema: `
    mutation SubmitCashTransaction(
      $transactionItem: CashTransactionMutationRequest
    ) {
      accountingMutations {
        submitCashTransaction(transactionItem: $transactionItem)
      }
    }
  `
};

export const ADD_CURRENCY = {
  routesNum: 2,
  gqlSchema: `
  mutation AddCurrency($currencyItem: CurrencyManagementMutationRequest) {
    systemManagementMutations {
      addCurrency(currencyItem: $currencyItem)
    }
  }
`
};

export const CREATE_OR_UPDATE_CURRENCY_RATES = {
  routesNum: 2,
  gqlSchema: `
  mutation CreateOrUpdateCurrencyRates($currencyRateItems: [CurrencyRateItem]) {
    tpmMutations {
      createOrUpdateCurrencyRates(currencyRateItems: $currencyRateItems)
    }
  }
  `
};

export const REMOVE_CURRENCY = {
  routesNum: 2,
  gqlSchema: `
  mutation RemoveCurrency($currencyCode: String) {
    systemManagementMutations {
      removeCurrency(currencyCode: $currencyCode) {
        isDone
        stateId
      }
    }
  }
`
};

export const SUBMIT_CURRENCY_CALCULATION = {
  routesNum: 2,
  gqlSchema: `
  mutation SubmitCurrencyCalculation(
    $currencyItem: CurrencyCalculationMutationRequest
  ) {
    systemManagementMutations {
      submitCurrencyCalculation(currencyItem: $currencyItem)
    }
  }
`
};

export const SUBMIT_OR_UPDATE_OUTGOING_DRAFT = {
  routesNum: 2,
  gqlSchema: `
    mutation SubmitOrUpdateOutgoingDraft(
      $draftItem: DraftMutationRequest
    ) {
      branchManagementMutations {
        submitOrUpdateOutgoingDraft(draftItem: $draftItem)
      }
    }
  `
};

export const SUBMIT_OR_UPDATE_INCOMING_DRAFT = {
  routesNum: 2,
  gqlSchema: `
    mutation SubmitOrUpdateIncomingDraft(
      $draftItem: DraftMutationRequest
    ) {
      branchManagementMutations {
        submitOrUpdateIncomingDraft(draftItem: $draftItem)
      }
    }
  `
};

export const REMOVE_DRAFT = {
  routesNum: 2,
  gqlSchema: `
    mutation RemoveDraft($removeDraftRequestItem: RemoveDraftRequest) {
      branchManagementMutations {
        removeDraft(removeDraftRequestItem: $removeDraftRequestItem) {
          stateId
          isDone
        }
      }
    }
  `
};

export const REORDER_CURRENCIES = {
  routesNum: 2,
  gqlSchema: `
    mutation ReorderCurrencies($currencyItems: [String]) {
      systemManagementMutations {
        reorderCurrencies(currencyItems: $currencyItems)
      }
    }
  `
};

export const REGISTER_OR_UPDATE_PERSON = {
  routesNum: 2,
  gqlSchema: `
    mutation RegisterOrUpdatePerson($personItem: PersonMutationRequest) {
      identityMutations {
        registerOrUpdatePerson(personItem: $personItem)
      }
    }
  `
};
export const REGISTER_PERSON_FROM_EXCEL_FILE = {
  routesNum: 2,
  gqlSchema: `
    mutation RegisterCustomersByExcelFile($personRequest: [RegisterPersonByExcelFileRequest]) {
      identityMutations {
        registerCustomersByExcelFile(personRequest: $personRequest) {
          isDone
          stateId
        }
      }
    }
  `
};
export const SUBMIT_CONVERSION = {
  routesNum: 2,
  gqlSchema: `
    mutation SubmitDraftDocumentCurrencyConversion(
      $conversionItem: BranchConversionCurrencyMutationRequest
    ) {
      branchManagementMutations {
        submitDraftDocumentCurrencyConversion(conversionItem: $conversionItem)
      }
    }
  `
};

export const SUBMIT_ROW_BASED_BRANCH_CHECKOUT = {
  routesNum: 2,
  gqlSchema: `
    mutation SubmitRowBasedBranchCheckout(
      $checkoutItem: RowBasedBranchCheckoutMutationRequest
    ) {
      branchManagementMutations {
        submitRowBasedBranchCheckout(checkoutItem: $checkoutItem) {
          isDone
          stateId
        }
      }
    }
  `
};

export const SUBMIT_BALANCE_BASED_BRANCH_CHECKOUT = {
  routesNum: 2,
  gqlSchema: `
    mutation SubmitBalanceBasedBranchCheckout(
      $checkoutItem: BalanceBasedBranchCheckoutMutationRequest
    ) {
      branchManagementMutations {
        submitBalanceBasedBranchCheckout(checkoutItem: $checkoutItem) {
          isDone
          stateId
        }
      }
    }
  `
};

export const REMOVE_BRANCH_CHECKOUT = {
  routesNum: 2,
  gqlSchema: `
    mutation RemoveBranchCheckout($branchId: String) {
      branchManagementMutations {
        removeBranchCheckout(branchId: $branchId){
          isDone
          stateId
        }
      }
    }
  `
};

export const SUBMIT_DATE_BASED_CUSTOMER_CHECKOUT = {
  routesNum: 2,
  gqlSchema: `
    mutation SubmitDateBasedCustomerCheckout(
      $checkoutItem: DateBasedCustomerCheckoutMutationRequest
    ) {
      accountingMutations {
        submitDateBasedCustomerCheckout(checkoutItem: $checkoutItem) {
          isDone
          stateId
        }
      }
    }
  `
};

export const SUBMIT_ROW_BASED_CUSTOMER_CHECKOUT = {
  routesNum: 2,
  gqlSchema: `
    mutation SubmitRowBasedCustomerCheckout(
      $checkoutItem: RowBasedCustomerCheckoutMutationRequest
    ) {
      accountingMutations {
        submitRowBasedCustomerCheckout(checkoutItem: $checkoutItem) {
          isDone
          stateId
        }
      }
    }
  `
};

export const SUBMIT_BALANCE_BASED_CUSTOMER_CHECKOUT = {
  routesNum: 2,
  gqlSchema: `
    mutation SubmitBalanceBasedCustomerCheckout(
      $checkoutItem: BalanceBasedCustomerCheckoutMutationRequest
    ) {
      accountingMutations {
        submitBalanceBasedCustomerCheckout(checkoutItem: $checkoutItem) {
          isDone
          stateId
        }
      }
    }
  `
};

export const REMOVE_CUSTOMER_CHECKOUT = {
  routesNum: 2,
  gqlSchema: `
    mutation RemoveCustomerCheckout($accountNumber: String) {
      accountingMutations {
        removeCustomerCheckout(accountNumber: $accountNumber) {
          isDone
          stateId
        }
      }
    }
  `
};

export const SUBMIT_DATE_BASED_BANK_CHECKOUT = {
  routesNum: 2,
  gqlSchema: `
    mutation SubmitDateBasedBankCheckout($checkoutItem: DateBasedBankCheckoutMutationRequest) {
        bankingMutations {
          submitDateBasedBankCheckout(checkoutItem: $checkoutItem) {
            isDone
            stateId
          }
        }
      }
    `
};

export const SUBMIT_ROW_BASED_BANK_CHECKOUT = {
  routesNum: 2,
  gqlSchema: `
  mutation SubmitRowBasedBankCheckout($checkoutItem: RowBasedBankCheckoutMutationRequest) {
    bankingMutations {
      submitRowBasedBankCheckout(checkoutItem: $checkoutItem) {
        isDone
        stateId
      }
    }
  }
`
};

export const SUBMIT_BALANCE_BASED_BANK_CHECKOUT = {
  routesNum: 2,
  gqlSchema: `
  mutation SubmitBalanceBasedBankCheckout($checkoutItem: BalanceBasedBankCheckoutMutationRequest) {
    bankingMutations {
      submitBalanceBasedBankCheckout(checkoutItem: $checkoutItem) {
        isDone
        stateId
      }
    }
  }
`
};

export const REMOVE_BANK_CHECKOUT = {
  routesNum: 2,
  gqlSchema: `
  mutation RemoveBankCheckout($bankAccountId: String!) {
    bankingMutations {
      removeBankCheckout(bankAccountId: $bankAccountId) {
        isDone
        stateId
      }
    }
  }
`
};

export const REMOVE_BRANCH = {
  routesNum: 2,
  gqlSchema: `
    mutation RemoveBranch($branchId: String!) {
      branchManagementMutations {
        removeBranch(branchId: $branchId)
      }
    }
  `
};

export const REMOVE_ADDRESS = {
  routesNum: 2,
  gqlSchema: `
    mutation RemoveAddress($branchId: String) {
      branchManagementMutations {
        removeBranch(branchId: $branchId)
      }
    }
  `
};

export const REGISTER_BANK_ACCOUNT = {
  routesNum: 2,
  gqlSchema: `
    mutation RegisterBankAccount($bankAccountItem: BankAccountMutationRequest) {
      bankingMutations {
        registerBankAccount(bankAccountItem: $bankAccountItem)
      }
    }
  `
};

export const UPDATE_BANK_ACCOUNT = {
  routesNum: 2,
  gqlSchema: `
    mutation UpdateBankAccount($bankAccountItem: BankAccountUpdateMutationRequest) {
      bankingMutations {
        updateBankAccount(bankAccountItem: $bankAccountItem)
      }
    }
  `
};

export const REMOVE_PERSON_ADDRESS = {
  routesNum: 2,
  gqlSchema: `
    mutation RemoveAddress($addressItem: AddressQueryRequest) {
      identityMutations {
        removeAddress(addressItem: $addressItem)
      }
    }
  `
};

export const REMOVE_PERSON_DOCUMENT = {
  routesNum: 2,
  gqlSchema: `
    mutation RemoveDocument($documentItem: DocumentQueryRequest) {
      identityMutations {
        removeDocument(documentItem: $documentItem)
      }
    }
  `
};

export const REMOVE_PERSON_BANK_INFO = {
  routesNum: 2,
  gqlSchema: `
    mutation RemoveBankInfo($bankInfoItem: BankInfoQueryRequest) {
      identityMutations {
        removeBankInfo(bankInfoItem: $bankInfoItem)
      }
    }
  `
};

export const SUBMIT_OR_UPDATE_PERSON_ADDRESS = {
  routesNum: 2,
  gqlSchema: `
    mutation SubmitOrUpdateAddress($addressItem: PersonAddressMutationRequest) {
      identityMutations {
        submitOrUpdateAddress(addressItem: $addressItem)
      }
    }
  `
};

export const SUBMIT_OR_UPDATE_PERSON_DOCUMENT = {
  routesNum: 2,
  gqlSchema: `
    mutation SubmitOrUpdateDocument($documentItem: PersonDocumentMutationRequest) {
      identityMutations {
        submitOrUpdateDocument(documentItem: $documentItem)
      }
    }
  `
};

export const SUBMIT_OR_UPDATE_PERSON_BANK_INFO = {
  routesNum: 2,
  gqlSchema: `
    mutation SubmitOrUpdateBankInfo($bankInfoItem: PersonBankInfoMutationRequest) {
      identityMutations {
        submitOrUpdateBankInfo(bankInfoItem: $bankInfoItem)
      }
    }
  `
};

export const SUBMIT_PERSONAL_DETAILS = {
  routesNum: 2,
  gqlSchema: `
    mutation SubmitPersonalDetails(
      $personalDetailsItem: PersonPersonalDetailMutationRequest
    ) {
      identityMutations {
        submitPersonalDetails(personalDetailsItem: $personalDetailsItem)
      }
    }
  `
};

export const SUBMIT_OR_UPDATE_COMPANY_CONTACT_INFO = {
  routesNum: 2,
  gqlSchema: `
    mutation SubmitOrUpdateContactInfo($contactInfoItem: ContactInfoMutationRequest) {
      identityMutations {
        submitOrUpdateContactInfo(contactInfoItem: $contactInfoItem)
      }
    }
  `
};

export const REMOVE_COMPANY_CONTACT_INFO = {
  routesNum: 2,
  gqlSchema: `
    mutation RemoveContactInfo($phoneInfo: ContactInfoRemoveMutationRequest) {
      identityMutations {
        removeContactInfo(phoneInfo: $phoneInfo)
      }
    }
  `
};

export const SUBMIT_CHEQUE_OPERATION = {
  routesNum: 2,
  gqlSchema: `
    mutation SubmitChequeOperation($operationItem: ChequeOperationMutationRequest) {
      accountingMutations {
        submitChequeOperation(operationItem: $operationItem) {
          isDone
          stateId
        }
      }
    }
  `
};

export const SUBMIT_OR_UPDATE_SHAREHOLDER = {
  routesNum: 2,
  gqlSchema: `
    mutation SubmitOrUpdateShareholder(
      $shareholderItem: PersonShareholderMutationRequest
    ) {
      identityMutations {
        submitOrUpdateShareholder(shareholderItem: $shareholderItem)
      }
    }
  `
};

export const REMOVE_SHAREHOLDER = {
  routesNum: 2,
  gqlSchema: `
    mutation RemoveShareholder(
      $shareholderItem: RemovePersonSharehodlerMutationRequest 
      ) {
      identityMutations  {
        removeShareholder(shareholderItem: $shareholderItem)
      }
    }
  `
};

export const REMOVE_BANK_TRANSACTION = {
  routesNum: 2,
  gqlSchema: `
    mutation RemoveBankTransaction($removeBankTransatcionRequestItem: RemoveBankTransatcionRequestItem) {
      bankingMutations {
        removeBankTransaction(removeBankTransatcionRequestItem: $removeBankTransatcionRequestItem) {
          stateId
          isDone
        }
      }
    }
  `
};

export const REMOVE_CHEQUE = {
  routesNum: 2,
  gqlSchema: `
    mutation RemoveCheque($removeChequeRequestItem: RemoveChequeRequestItem) {
      accountingMutations {
        removeCheque(removeChequeRequestItem: $removeChequeRequestItem) {
          isDone
          stateId
        }
      }
    }
  `
};

export const REMOVE_ACCOUNT_TRANSACTION = {
  routesNum: 2,
  gqlSchema: `
    mutation RemoveAccountTransaction($removeAccountTransactionRequestItem: RemoveAccountTransactionRequest) {
      accountingMutations {
        removeAccountTransaction(removeAccountTransactionRequestItem: $removeAccountTransactionRequestItem) {
          isDone
          stateId
        }
      }
    }
  `
};

export const REMOVE_TRADE_CURRENCY = {
  routesNum: 2,
  gqlSchema: `
    mutation RemoveTradeCurrency($transaction: RemoveTradeCurrencyRequest) {
      accountingMutations {
        removeTradeCurrency(transaction: $transaction) {
          isDone
          stateId
        }
      }
    }
  `
};

export const REMOVE_INTER_BANK_TRANSACTION = {
  routesNum: 2,
  gqlSchema: `
    mutation RemoveInterBankTransaction($interTransactionId: String) {
      bankingMutations {
        removeInterBankTransaction(interTransactionId: $interTransactionId) {
          isDone
          stateId
        }
      }
    }
  `
};

export const TOGGLE_BANKBOOK_TRANSACTION_HIGHLIGHT = {
  routesNum: 2,
  gqlSchema: `
    mutation toggleBankReceipt($toggleItem: ToggleHighlightBankReceiptRequestItem){
      bankingMutations{
        toggleHighlightBankReceipt(toggleHighlightBankReceiptRequestItem:$toggleItem){
          isDone
        }
      }
    }
  `
};

export const SUBMIT_BANK_TRANSACTIONS = {
  routesNum: 2,
  gqlSchema: `
    mutation SubmitBankTransactions($bankReceiptItem: BankReceiptMutationRequest) {
      bankingMutations {
        submitBankTransactions(bankReceiptItem: $bankReceiptItem) {
          isDone
          stateId
        }
      }
    }
  `
};

export const REMOVE_PERSON_ATTACHMENT = {
  routesNum: 2,
  gqlSchema: `
    mutation RemoveAttachment($attachment: RemovePersonAttachmentRequest) {
      identityMutations {
        removeAttachment(attachment: $attachment) {
          isDone
          stateId
        }
      }
    }
  `
};

export const REMOVE_PERSON_OR_COMPANY = {
  routesNum: 2,
  gqlSchema: `
    mutation RemovePersonOrCompany($personId: ID) {
      identityMutations {
        removePersonOrCompany(personId: $personId) {
          isDone
          stateId
        }
      }
    }
  `
};

export const CREATE_PAYMENT_PLACE = {
  routesNum: 2,
  gqlSchema: `
  mutation CreatePaymentPlace(
      $paymentPlaceItem: AddPaymentPlaceMutationRequest
    ) {
      rechargeMutations {
        createPaymentPlace(paymentPlaceItem: $paymentPlaceItem)
      }
    }
  `
};

export const CREATE_ACCOUNT = {
  routesNum: 2,
  gqlSchema: `
  mutation CreateAccount(
      $accountItem: AddAccountMutationRequest
    ) {
      rechargeMutations {
        createAccount(accountItem: $accountItem)
      }
    }
  `
};

export const UPDATE_CHARGE_ACCOUNT = {
  routesNum: 2,
  gqlSchema: `
    mutation UpdateAccount(
      $accountItem: EditAccountMutationRequest
    ) {
      rechargeMutations {
        updateAccount(accountItem: $accountItem)
      }
    }
  `
};

export const DELETE_ACCOUNT = {
  routesNum: 2,
  gqlSchema: `
    mutation DeleteAccount(
      $accountId: Int
      ) {
        rechargeMutations {
        deleteAccount(accountId: $accountId)
      }
    }
  `
};

export const CREATE_EXCHANGER_RATE = {
  routesNum: 2,
  gqlSchema: `
  mutation CreateExchangerRate(
      $exchangerRateItem: AddExchangerRateMutationRequest
    ) {
      rechargeMutations {
        createExchangerRate(exchangerRateItem: $exchangerRateItem)
      }
    }
  `
};

export const UPDATE_EXCHANGER_RATE = {
  routesNum: 2,
  gqlSchema: `
  mutation UpdateExchangerRate(
      $exchangerRateItem: EditExchangerRateMutationRequest
    ) {
      rechargeMutations {
        updateExchangerRate(exchangerRateItem: $exchangerRateItem)
      }
    }
  `
};
export const DELETE_EXCHANGER_RATE = {
  routesNum: 2,
  gqlSchema: `
    mutation DeleteExchangerRate(
      $exchangerRateId: Int
    ) {
      rechargeMutations {
        deleteExchangerRate(exchangerRateId: $exchangerRateId)
      }
    }
  `
};

export const CREATE_RATE = {
  routesNum: 2,
  gqlSchema: `
    mutation CreateRate(
      $rateItem: RateRechargeMutationRequest
    ) {
      rechargeMutations {
        createRate(rateItem: $rateItem)
      }
    }
  `
};

export const REMOVE_ACCOUNT = {
  routesNum: 2,
  gqlSchema: `
    mutation RemoveAccount($accountNumber: String) {
      accountingMutations {
        removeAccount(accountNumber: $accountNumber) {
          isDone
          stateId
        }
      }
    }
  `
};

export const CHANGE_ACCOUNT_ACTIVATION = {
  routesNum: 2,
  gqlSchema: ` 
    mutation changeAccountEnablity($accountId: String!){
      accountingMutations {
        changeAccountEnablity(AccountId: $accountId) {
          isDone
        }
      }
    }
  `
};

export const REGISTER_SYSTEM_ACCOUNT = {
  routesNum: 2,
  gqlSchema: `
    mutation RegisterSystemAccount(
      $systemAccountItem: SystemAccountMutationRequest
    ) {
      accountingMutations {
        registerSystemAccount(systemAccountItem: $systemAccountItem)
      }
    }
  `
};

export const UPDATE_ACCOUNT = {
  routesNum: 2,
  gqlSchema: `
    mutation UpdateAccount(
      $accountItem: EditCustomerAccountMutationRequest
    ) {
      accountingMutations {
        updateAccount(accountItem: $accountItem) {
            isDone
            stateId
        }
      }
    }
  `
};

export const REGISTER_ACCOUNT = {
  routesNum: 2,
  gqlSchema: `
    mutation RegisterAccount($accountItem: CustomerAccountMutationRequest) {
      accountingMutations {
        registerAccount(accountItem: $accountItem) {
          isDone
          stateId
        }
      }
    }
  `
};

export const SET_DOCUMENT_ACTIVE = {
  routesNum: 2,
  gqlSchema: `
    mutation SetDocumentActive($documentItem: PersonDocumentChangeActiveMutationRequest) {
      identityMutations {
        setDocumentActive(documentItem: $documentItem)
      }
    }
  `
};

export const SET_CONTACT_INFO_ACTIVE = {
  routesNum: 2,
  gqlSchema: `
    mutation SetContactInfoActive($phoneInfo: ContactInfoChangeActiveMutationRequest) {
      identityMutations {
        setContactInfoActive(phoneInfo: $phoneInfo)
      }
    }
  `
};

export const SET_ADDRESS_ACTIVE = {
  routesNum: 2,
  gqlSchema: `
    mutation SetAddressActive($addressItem: PersonAddressChangeActivateMutationRequest) {
      identityMutations {
        setAddressActive(addressItem: $addressItem)
      }
    }
  `
};
export const TOGGLE_ACCOUNT_CHARGE_STATUS = {
  routesNum: 2,
  gqlSchema: `
  mutation ToggleAccountChargeStatus($personId: String) {
    rechargeMutations {
      toggleAccountChargeStatus(personId: $personId)
    } 
  }
  `
};

export const REMOVE_BANK_ACCOUNT = {
  routesNum: 2,
  gqlSchema: `
    mutation RemoveBankAccount($bankAccountId: String) {
      bankingMutations {
        removeBankAccount(bankAccountId: $bankAccountId)
      }
    }
  `
};

export const REGISTER_PAYMENT_ORDER = {
  routesNum: 2,
  gqlSchema: `
    mutation RegisterPaymentOrder($paymentOrderItem: PaymentOrderMutationRequest) {
      accountingMutations {
        registerPaymentOrder(paymentOrderItem: $paymentOrderItem)
      }
    }
  `
};

export const UPDATE_PAYMENT_ORDER = {
  routesNum: 2,
  gqlSchema: `
    mutation UpdatePaymentOrder($paymentOrderItem: EditPaymentOrderMutationRequest) {
      accountingMutations {
        updatePaymentOrder(paymentOrderItem: $paymentOrderItem)
      }
    }
  `
};

export const REMOVE_PAYMENT_ORDER = {
  routesNum: 2,
  gqlSchema: `
    mutation RemovePaymentOrder($paymentOrderId: ID) {
      accountingMutations {
        removePaymentOrder(paymentOrderId: $paymentOrderId)
      }
    }
    `
};

export const TOGGLE_CHARGE_STATUS = {
  routesNum: 2,
  gqlSchema: `
  mutation ToggleChargeStatus($personId:String) {
    rechargeMutations {
      toggleChargeStatus(personId: $personId)
    }
  }
  `
};

export const REMOVE_CURRENCY_CALCULATION = {
  routesNum: 2,
  gqlSchema: `
  mutation RemoveCurrencyCalculation($rowNumber: Int) {
    systemManagementMutations {
      removeCurrencyCalculation(rowNumber: $rowNumber)
    }
  }
  `
};

export const CONNECT_BRANCH = {
  routesNum: 2,
  gqlSchema: `
  mutation ConnectBranch($connectBranchItem: ConnectBranchItemMutationRequest) {
    branchManagementMutations {
      connectBranch(connectBranchItem: $connectBranchItem)
    }
  }
  `
};

export const REGISTER_BRANCH_FIRST_STEP = {
  routesNum: 2,
  gqlSchema: `
  mutation RegisterBranchFirstStep($branchItem: BranchFirstStepMutationRequest) {
    branchManagementMutations {
      registerBranchFirstStep(branchItem: $branchItem)
    }
  }
  `
};

export const UPDATE_BRANCH_FIRST_STEP = {
  routesNum: 2,
  gqlSchema: `
  mutation UpdateBranchFirstStep($branchItem: BranchFirstStepUpdateMutationRequest) {
    branchManagementMutations {
      updateBranchFirstStep(branchItem: $branchItem)
    }
  }
  `
};

export const REGISTER_BRANCH_SECOND_STEP = {
  routesNum: 2,
  gqlSchema: `
  mutation RegisterBranchSecondStep(
    $branchItem: BranchSecondStepMutationRequest
  ) {
    branchManagementMutations {
      registerBranchSecondStep(branchItem: $branchItem)
    }
  }
  `
};
export const ADD_PAYMENT_PLACE = {
  routesNum: 2,
  gqlSchema: `
  mutation AddPaymentPlace( $branchItem: AddPaymentPlaceRequestItem ) {
    branchManagementMutations {
      addPaymentPlace(addPaymentPlaceRequestItem: $branchItem)
    }
  }
  `
};
export const UPDATE_PAYMENT_PLACE = {
  routesNum: 2,
  gqlSchema: `
  mutation UpdatePaymentPlace( $branchItem: UpdatePaymentPlaceRequestItem ) {
    branchManagementMutations {
      updatePaymentPlace(updatePaymentPlaceRequestItem: $branchItem)
    }
  }
  `
};
export const DELETE_PAYMENT_PLACE = {
  routesNum: 2,
  gqlSchema: `
  mutation DeletePaymentPlace(
    $paymentPlaceId: Int
    ) {
      rechargeMutations {
        deletePaymentPlace(paymentPlaceId: $paymentPlaceId)
      }
    }
  `
};
export const REMOVE_BRANCH_PAYMENT_PLACE = {
  routesNum: 2,
  gqlSchema: `
  mutation RemoveBranchPaymentPlaces ($branchItem: RemovePaymentPlaceRequestItem){
    branchManagementMutations {
      removePaymentPlace(removePaymentPlaceRequestItem: $branchItem)
    }
  }
  `
};
export const DISCONNECT_BRANCH = {
  routesNum: 2,
  gqlSchema: `
  mutation DisconnectBranch(
    $branchId: String!
  ) {
    branchManagementMutations {
      disconnectBranch(branchId: $branchId)
    }
  }
  `
};

export const EDIT_MAIN_CURRENCY = {
  routesNum: 2,
  gqlSchema: `
  mutation EditMainCurrency(
    $currencyCode: String
  ) {
    systemManagementMutations {
      editMainCurrency(currencyCode: $currencyCode)
    }
  }
  `
};

export const SUBMIT_INCOMING_DRAFT_FROM_OUTSIDE = {
  routesNum: 2,
  gqlSchema: `
  mutation SubmitIncomingDraftFromOutside(
    $submitIncomingBranchItem: IncomingDraftFromOutsideRequest
  ) {
    branchManagementMutations {
      submitIncomingDraftFromOutside(submitIncomingBranchItem: $submitIncomingBranchItem) {
        isDone
        stateId
      }
    }
  }
  `
};
export const ASSIGN_REFERRAL = {
  routesNum: 2,
  gqlSchema: `
  mutation SubmitAssignReferral($ticketItem: ReferTicketMutationRequest) {
    supportMutations {
      assignReferral(ticketItem: $ticketItem)
    }
  }
  `
};

export const ASSIGN_REFERRAL_TO_DEVELOPERS = {
  routesNum: 2,
  gqlSchema: `
  mutation SubmitAssignReferralToDevelopers($referralCommandItem: ReferralTicketBetweenDeveloperMutationRequest) {
    supportMutations {
      assignReferralBetweenDeveloper(referralCommandItem: $referralCommandItem)
    }
  }
  `
};

export const SET_TICKET_STATE = {
  routesNum: 2,
  gqlSchema: `
  mutation SetTicketState($ticketInfo: TicketInfoMutationRequest) {
    supportMutations {
      setTicketState(ticketInfo: $ticketInfo)
    }
  }
  `
};

export const REJECT_PENDING_DRAFTS = {
  routesNum: 2,
  gqlSchema: `
  mutation RejectPendDrafts($rejectPendDraftsRequest: RejectPendDraftsRequest!) {
    branchManagementMutations {
      rejectPendDrafts(rejectPendDraftsRequest: $rejectPendDraftsRequest)
    }
  }
  `
};

export const CONFIRM_PENDING_DRAFTS = {
  routesNum: 2,
  gqlSchema: `
  mutation ConfirmPendDrafts($confirmPendDraftRequest: ConfirmPendDraftRequest!) {
    branchManagementMutations {
      confirmPendDraft(confirmPendDraftRequest: $confirmPendDraftRequest){
        isDone
        stateId
      }
    }
  }
  `
};

export const ADD_USER_MUTATION = {
  routesNum: 2,
  gqlSchema: `
    mutation SubmitMigration($migrationItem: MigrationMutationRequest) {
      migrationMutations {
        submitMigration(migrationItem: $migrationItem) {
          isDone
          stateId
        }
      }
    }
  `
};

export const CREATE_PACKAGE = {
  routesNum: 2,
  gqlSchema: `
    mutation CreatePackage($packageItem: CreatePackageItemRequest) {
      tpmMutations {
        createPackage(packageItem: $packageItem)
      }
    }
  `
};

export const UPDATE_PACKAGE = {
  routesNum: 2,
  gqlSchema: `
    mutation UpdatePackage($packageItem: UpdatePackageItemRequest!) {
      tpmMutations {
        updatePackage(packageItem: $packageItem)
      }
    }
  `
};

export const REMOVE_PACKAGE = {
  routesNum: 2,
  gqlSchema: `
    mutation RemovePackages($packageId: Int!) {
      tpmMutations {
        removePackage(packageId: $packageId)
      }
    }
  `
};

export const CHANGE_FEATURE_ACTIVATION = {
  routesNum: 2,
  gqlSchema: `
    mutation ChangeFeatureActivation($featureId: Int) {
      tpmMutations {
        changeFeatureActivation(featureId: $featureId)
      }
    }
  `
};

export const CHANGE_OPERATOR_ACTIVATION = {
  routesNum: 2,
  gqlSchema: `
    mutation ChangeOperatorActivation ($requestItem: ChangeOperatorActivationItem) {
      tpmMutations {
        changeOperatorActivation(requestItem: $requestItem)
      }
    }
  `
};

export const REFUND_MONEY = {
  routesNum: 2,
  gqlSchema: `
  mutation refund ($transactionIds:[String]){
    tpmMutations{
      refundMoney(transactionIds:$transactionIds)
    }
  }
  `
};

export const SUBMIT_GARDESHGARI_BON_CARD_REQUEST = {
  routesNum: 2,
  gqlSchema: `
    mutation SubmitCustomerRegistration ($submitCustomerRegistrationRequestItem: SubmitCustomerRegistrationRequestItem) {
      gardeshgariMutations {
        submitCustomerRegistration(submitCustomerRegistrationRequestItem: $submitCustomerRegistrationRequestItem) {
          isDone
          stateId
        }
      }
    }
  `
};

export const ACCEPT_OR_DECLINE_CUSTOMER_REGISTRATION_BY_SUPPORT = {
  routesNum: 2,
  gqlSchema: `
    mutation AcceptOrDeclineCustomerRegistrationBySupport ($acceptOrDeclineCustomerRegistration: AcceptOrDeclineCustomerRegistrationBySupport) {
      gardeshgariMutations {
        acceptOrDeclineCustomerRegistrationBySupport(acceptOrDeclineCustomerRegistration: $acceptOrDeclineCustomerRegistration) {
          isDone
          stateId
        }
      }
    }
  `
};

export const ADD_CURRENCY_RATE_TO_PAYMENT_PLACE = {
  routesNum: 2,
  gqlSchema: `
  mutation AddCurrencyRatesToPaymentPlace ($addCurrencyRatesToPaymentPlaceRequestItem: AddCurrencyRatesToPaymentPlaceRequestItem!) {
    branchManagementMutations {
      addCurrencyRatesToPaymentPlace (addCurrencyRatesToPaymentPlaceRequestItem: $addCurrencyRatesToPaymentPlaceRequestItem)
    }
  }
  `
};

export const REMOVE_CURRENCY_RATES_FROM_PAYMENT_PLACE = {
  routesNum: 2,
  gqlSchema: `
  mutation RemoveCurrencyRatesFromPaymentPlace ($removeCurrencyRatesFromPaymentPlaceRequestItem: RemoveCurrencyRatesFromPaymentPlaceRequestItem) {
    branchManagementMutations {
      removeCurrencyRatesFromPaymentPlace (removeCurrencyRatesFromPaymentPlaceRequestItem: $removeCurrencyRatesFromPaymentPlaceRequestItem)
    }
  }
  `
};

export const EDIT_RATE_EXPIRE_DATE = {
  routesNum: 2,
  gqlSchema: `
  mutation EditRateExpireDate ($rateExpireDate: Long) {
    systemManagementMutations {
      editRateExpireDate (rateExpireDate: $rateExpireDate)
    }
  }
  `
};
export const TOGGLE_HIGHLIGHT_DOCUMENT = {
  routesNum: 2,
  gqlSchema: `
  mutation setHighlightDoc($toggleHighlightDocumentRequestItem: ToggleHighlightDocumentRequestItem){
    accountingMutations{
      toggleHighlightDocument(toggleHighlightDocumentRequestItem:$toggleHighlightDocumentRequestItem){
        isDone,
        stateId
      }
    }
  }
  `
};
export const TOGGLE_HIGHLIGHT_STATUS = {
  routesNum: 2,
  gqlSchema: `
  mutation toggleHighlightStatus($highlightRequest: HighlightRequest){
    branchManagementMutations{
      toggleHighlightStatus(highlightRequest:$highlightRequest)
    }
  }
  `
};
export const GET_MISCELLANEOUS = {
  routesNum: 2,
  gqlSchema: (items) => `
    query GetMiscellaneous($transactionId: String) {
      accountingQueries {
          getMiscellaneous(transactionId: $transactionId) {
            ${items}
          }
        }
      }
  `
};
// *********************** end mutations ******************************

import React from 'react';
import useLayoutTypeContext from '../hooks/useLayoutTypeContext';
import AryModal from './AryModal';
import './modal.css';

export const AryAlertTypes = {
  ERROR: 'ERROR',
  SUCCESS: 'SUCCESS',
  WARNING: 'WARNING'
};

const getColor = (type) => {
  switch (type) {
    case AryAlertTypes.ERROR:
      return 'orange';
    case AryAlertTypes.SUCCESS:
      return 'green';
    case AryAlertTypes.WARNING:
      return 'blue';
    default:
      return null;
  }
};
const LogoWrapper = ({ type, children }) => {
  const bgColor = getColor(type);
  return (
    <div className={`${bgColor} logo-wrapper`}>
      <div className="logo-holder">
        {children}
      </div>
    </div>
  );
};
const AryAlert = ({
  open,
  handleClose,
  title,
  type,
  children,
  width,
  noCloseIcon,
  style
}) => {
  const layoutType = useLayoutTypeContext();

  const Logo = () => {
    const AlertLogo = () => {
      switch (type) {
        case AryAlertTypes.ERROR:
          return (
            <img
              src="/static/img/brands/caution-sign.svg"
              alt="reportProblem"
              style={{ height: '1rem', width: '1rem' }}
            />
          );
        case AryAlertTypes.SUCCESS:
          return (
            <img
              src="/static/img/brands/check-circle.svg"
              alt="reportProblem"
              style={{ height: '1rem', width: '1rem' }}
            />
          );
        case AryAlertTypes.WARNING:
          return (
            <img
              src="/static/img/brands/info.svg"
              alt="info"
              style={{ height: '1rem', width: '1rem' }}
            />
          );
        default:
          return null;
      }
    };
    return (
      <LogoWrapper type={type}>
        <AlertLogo />
      </LogoWrapper>
    );
  };

  return (
    <AryModal
      width={width || (layoutType === 'mobile' ? '80vw' : '21rem')}
      open={open}
      handleClose={handleClose}
      noCloseOnOutsideClicked
      noCloseIcon={noCloseIcon}
      style={{ 'padding-bottom': '2rem', ...style }}
    >
      <div className="modal-logo--wrapper">
        <Logo />
      </div>
      <div className="modal-text--holder">
        <h5 className="modal-title">{title}</h5>
      </div>
      {children}
    </AryModal>
  );
};

export default AryAlert;
